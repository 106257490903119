<template>
  <div class="faq-page">
    <h1 class="faq-title">
      FAQ
    </h1>
    <div class="main-content">
      <v-expansion-panels
        flat
        accordion
        class="expansion-panels"
      >
        <v-expansion-panel
          v-for="faq in faqs"
          :key="faq.id"
          class="expansion-panel"
        >
          <v-expansion-panel-header disable-icon-rotate class="header-panel">
            <span class="item-title">
              <span class="rich-text" v-html="faq.title" />
            </span>
            <template #actions>
              <div class="action-block">
                <span class="action-title">Read More</span>
                <v-icon color="var(--default-icon-color)">
                  mdi-menu-down
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="var(--tertiary-bg-color)" class="content-panel">
            <BRRichTextRenderer :document="faq.text" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="support-container">
      <BRIcon
        class="question-mark"
        icon="question"
        width="64"
        height="74"
      />
      <span class="text">If you can’t find an answer on your question here, you can always ask us!</span>
      <a :href="`mailto:${contacts.email}`" class="contact-link">{{ contacts.email }}</a>
    </div>
  </div>
</template>

<script>
import { contacts } from '../../public/messages/contacts'
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'

export default {
  name: 'FAQ',
  components: {
    BRRichTextRenderer
  },
  data() {
    return {
      faqs: this.$contentful.faqs,
      contacts
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.faq-page {
  @include container;

  padding: 5px 0 0;

  @include breakpoint(medium) {
    padding: 37px 0 0;
  }
}

.faq-title {
  margin-bottom: 22px;
  font-size: var(--font-h1);

  @include breakpoint(medium) {
    margin-bottom: 36px;
    font-size: var(--font-display);
    text-align: center;
  }
}

.expansion-panels {
  border-top: var(--default-border);
  border-bottom: var(--default-border);
  padding-top: 20px;
  padding-bottom: 30px;
  gap: 5px;

  .header-panel {
    padding: 1px 10px 0 20px;
    min-height: 50px;

    @include breakpoint(medium) {
      padding: 0 23px 0 35px;
      height: 70px;
    }
  }

  .item-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
  }

  .action-block {
    display: flex;
    align-items: center;
  }

  .action-title {
    margin-right: 7px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--secondary-text-color);

    @include breakpoint-reverse(medium) {
      display: none;
    }
  }

  .content-panel {
    position: relative;
    border-bottom-right-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -30px;
      border-bottom-left-radius: 50%;
      width: 20px;
      height: 30px;
      box-shadow: 0 15px 0 0 var(--tertiary-bg-color);
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: -30px;
      border-bottom-right-radius: 50%;
      width: 20px;
      height: 30px;
      box-shadow: 0 15px 0 0 var(--tertiary-bg-color);
    }

    &::v-deep .v-expansion-panel-content__wrap {
      padding: 23px 20px 18px;
      font-size: var(--font-base);
      color: var(--secondary-text-color);

      @include breakpoint(medium) {
        padding: 27px 35px 35px;
      }
    }
  }

  &.v-expansion-panels {
    border-radius: 0;
  }

  @include breakpoint(medium) {
    padding-top: 55px;
    padding-bottom: 47px;
    gap: 15px;
  }
}

.v-expansion-panel.expansion-panel {
  border-radius: var(--border-radius-default);
  color: var(--text-color);
  background-color: var(--content-bg-color);
}

.v-expansion-panel--active .v-expansion-panel-header--active {
  border-radius: var(--border-radius-default);
}

.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  text-align: center;

  .text {
    margin-top: 21px;
    margin-bottom: 22px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--secondary-text-color);

    @include breakpoint(medium) {
      margin-top: 33px;
      margin-bottom: 13px;
    }
  }

  .question-mark {
    width: 50px;
    height: 58px;

    @include breakpoint(medium) {
      width: 64px;
      height: 74px;
    }
  }

  @include breakpoint(medium) {
    margin-top: 56px;
  }
}

.contact-link {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  color: var(--primary-text-color);
}
</style>
